import React from "react";
import {Provider} from "react-redux";
import {createStore} from "redux";
import {Reducer} from "./data/Reducer.js";
import {Switch, Route} from "react-router-dom";
import AppBar from "./common/AppBar.js";
import Job from "./views/Job.js";
import Search from "./views/Search.js";
import Bookmark from "./views/Bookmark.js";
import Skill from "./views/Skill.js";
import "./App.css";
import Footer from "./common/Footer.js";
import {useHistory} from "react-router-dom";
import ReactGA from "react-ga";

ReactGA.initialize("UA-169256717-1");

function App() {
  const history = useHistory();

  history.listen(location => {
    ReactGA.set({page: location.pathname + location.search});
    ReactGA.pageview(location.pathname + location.search);
  });

  return (
    <Provider store={createStore(Reducer)}>
      <div>
        <AppBar />
        <Switch>
          <Route exact path="/search/:searchText?">
            <Search />
          </Route>
          <Route exact path="/bookmark">
            <Bookmark />
          </Route>
          <Route exact path="/skill/:skill?">
            <Skill />
          </Route>
          <Route exact path="/:job?">
            <Job />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Provider>
  );
}

export default App;
