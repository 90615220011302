import ActionTypes from "./ActionTypes";
import storage from "local-storage";

const DEFAULT_TWEETS_PER_PAGE = 24;

const initialState = {
  allJobs: [
    "engineer",
    "android",
    "backend",
    "frontend",
    "fullstack",
    "ios",
    "security",
    "systems",
    "product design",
    "marketing",
    "content strategy",
    "teaching",
    "translator",
    "customer service",
    "data analyst",
    "accounting"
  ],
  allSkills: [
    "python",
    "javascript",
    "nodejs",
    "swift",
    "php",
    "ruby",
    "java",
    "sql",
    "excel",
    "wordpress",
    "html",
    "css",
    "saas",
    "research",
    "analytics",
    "management",
    "qa",
    "sales"
  ],
  displaySkill: undefined,
  displayJob: undefined,
  allTweets: [],
  displayTweets: [],
  tweetsPerPage: DEFAULT_TWEETS_PER_PAGE,
  serverError: null,
  clientError: null,
  displayOrderIndex: 0,
  bookmarkedTweets: JSON.parse(storage.get("chocolate")) || [],
  chipType: "job"
};

function Reducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_ALL_TWEETS:
      return Object.assign({}, state, {
        allTweets: action.allTweets,
        serverError: null
      });
    case ActionTypes.SET_DISPLAY_TWEETS_BY_JOB:
      const displayTweetsByJob = state.allTweets.filter(tweet =>
        tweet.tags.includes(action.displayJob)
      );
      const displayJob =
        action.displayJob === "" ? undefined : action.displayJob;

      return Object.assign({}, state, {
        displayJob: displayJob,
        tweetsPerPage: DEFAULT_TWEETS_PER_PAGE,
        displayTweets: displayTweetsByJob,
        displayOrderIndex: 0,
        clientError: null,
        chipType: "job",
        displaySkill: undefined
      });
    case ActionTypes.SET_DISPLAY_TWEETS_BY_SEARCH:
      const searchText = action.searchText.split(/[ ,-]+/);
      const displayTweetsBySearch = state.allTweets.filter(tweet =>
        searchText.every(word =>
          tweet.full_text
            .toLowerCase()
            .replace(/[ *_#]/g, "")
            .includes(word.toLowerCase())
        )
      );

      return Object.assign({}, state, {
        displayJob: undefined,
        tweetsPerPage: DEFAULT_TWEETS_PER_PAGE,
        displayTweets: displayTweetsBySearch,
        displayOrderIndex: 0,
        clientError: null
      });
    case ActionTypes.SET_DISPLAY_TWEETS_BY_BOOKMARK:
      const bookmarkedTweets = state.bookmarkedTweets.sort(function(
        tweet1,
        tweet2
      ) {
        return (
          new Date(tweet2.created_at).getTime() -
          new Date(tweet1.created_at).getTime()
        );
      });
      return Object.assign({}, state, {
        displayJob: undefined,
        tweetsPerPage: DEFAULT_TWEETS_PER_PAGE,
        displayTweets: bookmarkedTweets,
        displayOrderIndex: 0,
        clientError: null
      });
    case ActionTypes.SET_TWEETS_PER_PAGE:
      return Object.assign({}, state, {tweetsPerPage: action.tweetsPerPage});
    case ActionTypes.SET_ERROR_MESSAGE:
      if (action.errorType === "clientError") {
        return Object.assign({}, state, {clientError: action.errorMessage});
      } else if (action.errorType === "serverError") {
        return Object.assign({}, state, {serverError: action.errorMessage});
      }
      break;
    case ActionTypes.SET_DISPLAY_ORDER_INDEX:
      var sortedDisplayTweets = state.displayTweets;
      if (action.displayOrderIndex === 0) {
        sortedDisplayTweets = sortedDisplayTweets.sort(function(
          tweet1,
          tweet2
        ) {
          return (
            new Date(tweet2.created_at).getTime() -
            new Date(tweet1.created_at).getTime()
          );
        });
      } else if (action.displayOrderIndex === 1) {
        sortedDisplayTweets = sortedDisplayTweets.sort(function(
          tweet1,
          tweet2
        ) {
          return (
            new Date(tweet1.created_at).getTime() -
            new Date(tweet2.created_at).getTime()
          );
        });
      } else {
        sortedDisplayTweets = sortedDisplayTweets.sort(function(
          tweet1,
          tweet2
        ) {
          return tweet2.popularity_score - tweet1.popularity_score;
        });
      }
      return Object.assign({}, state, {
        displayOrderIndex: action.displayOrderIndex,
        displayTweets: sortedDisplayTweets
      });
    case ActionTypes.SET_BOOKMARKED_TWEETS:
      var newBookmarkedTweets = state.bookmarkedTweets.filter(
        tweet => tweet.id !== action.tweet.id
      );
      if (newBookmarkedTweets.length === state.bookmarkedTweets.length) {
        newBookmarkedTweets.push(action.tweet);
      }
      return Object.assign({}, state, {bookmarkedTweets: newBookmarkedTweets});
    case ActionTypes.SET_DISPLAY_TWEETS_BY_SKILL:
      const displayTweetsBySkill = state.allTweets.filter(tweet =>
        tweet.skills !== null && tweet.skills.includes(action.displaySkill)
      );
      const displaySkill =
        action.displaySkill === "" ? undefined : action.displaySkill;

      return Object.assign({}, state, {
        displaySkill: displaySkill,
        tweetsPerPage: DEFAULT_TWEETS_PER_PAGE,
        displayTweets: displayTweetsBySkill,
        displayOrderIndex: 0,
        clientError: null,
        chipType: "skill",
        displayJob: undefined
      });
    case ActionTypes.SET_CHIP_TYPE:
      return Object.assign({}, state, {chipType: action.chipType});
    default:
      return state;
  }
}

export {Reducer, DEFAULT_TWEETS_PER_PAGE};
