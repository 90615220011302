import React, {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import TwitterIcon from "@material-ui/icons/Twitter";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Link from "@material-ui/core/Link";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import SortIcon from "@material-ui/icons/Sort";
import Social from "./Social.js";
import Fab from "@material-ui/core/Fab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ActionTypes from "./data/ActionTypes.js";
import {DEFAULT_TWEETS_PER_PAGE} from "./data/Reducer.js";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ShareIcon from "@material-ui/icons/Share";
import storage from "local-storage";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import ReactGA from "react-ga";
import BookmarkBorderIcon from "@material-ui/icons/BookmarkBorder";
import {useOutlineSelectStyles} from "@mui-treasury/styles/select/outline";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import clsx from "clsx";
import Skeleton from "@material-ui/lab/Skeleton";

const useStyles = makeStyles({
  card: {
    borderRadius: 10,
    borderColor: "#496BA4"
  },
  sortMenuWidth: {
    minWidth: 120,
    borderRadius: 20
  },
  cardContent: {
    height: 50,
    overflow: "hidden"
  },
  cardContentCursor: {
    cursor: "pointer"
  }
});

const sortMenuOptions = ["Newest", "Oldest", "Trending"];

function Jobsfeed(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const outlineSelectClasses = useOutlineSelectStyles();

  // Global state
  const allTweets = useSelector(state => state.allTweets);
  const displayTweets = useSelector(state => state.displayTweets);
  const displayJob = useSelector(state => state.displayJob);
  const displaySkill = useSelector(state => state.displaySkill);
  const tweetsPerPage = useSelector(state => state.tweetsPerPage);
  const displayOrderIndex = useSelector(state => state.displayOrderIndex);
  const bookmarkedTweets = useSelector(state => state.bookmarkedTweets);

  // Local state
  const [anchorElShare, setAnchorElShare] = useState(null);
  const [shareText, setShareText] = useState("");
  const [expandCards, setExpandCards] = useState({});

  const handleCardExpansion = tweet => {
    const newExpandCards = {...expandCards, [tweet.id]: !expandCards[tweet.id]};
    setExpandCards(newExpandCards);
  };

  const menuProps = {
    classes: {
      paper: outlineSelectClasses.paper,
      list: outlineSelectClasses.list
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left"
    },
    getContentAnchorEl: null
  };

  const iconComponent = props => {
    return (
      <ExpandMoreRoundedIcon
        className={props.className + " " + outlineSelectClasses.icon}
      />
    );
  };

  const isTweetBookmarked = tweet => {
    return bookmarkedTweets.some(tweetObj => tweetObj.id === tweet.id);
  };

  useEffect(() => {
    storage.set("chocolate", JSON.stringify(bookmarkedTweets));
  }, [bookmarkedTweets]);

  const handleBookmark = tweet => {
    ReactGA.event({
      category: "Card Actions",
      action: "Bookmark Icon clicked"
    });
    dispatch({
      type: ActionTypes.SET_BOOKMARKED_TWEETS,
      tweet: tweet
    });
  };

  const handleShareMenuOpen = (event, full_text) => {
    ReactGA.event({
      category: "Card Actions",
      action: "Share Menu opened"
    });
    setAnchorElShare(event.currentTarget);
    setShareText(full_text);
  };

  const handleShareMenuClose = () => {
    setAnchorElShare(null);
  };

  const handleSortMenuItemClick = event => {
    const index = event.target.value;
    ReactGA.event({
      category: "Sort Menu",
      action: "sort option changed to " + sortMenuOptions[index]
    });
    dispatch({
      type: ActionTypes.SET_DISPLAY_ORDER_INDEX,
      displayOrderIndex: index
    });
  };

  const displayMessage =
    props.displayText === "job"
      ? displayJob === undefined
        ? ""
        : "for " + displayJob
      : props.displayText === "search"
      ? props.searchText === undefined
        ? ""
        : "for " + props.searchText
      : props.displayText === "skill"
      ? displaySkill === undefined
        ? ""
        : "for " + displaySkill
      : "that you bookmarked";

  return (
    <div>
      <Container className="jobsfeed-container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
          }}
        >
          <div style={{display: "flex", alignItems: "center"}}>
            <Typography className="jobsfeed-container-header">
              {allTweets.length === 0
                ? "Loading remote jobs..."
                : displayTweets.length === 0
                ? props.displayText === "search"
                  ? "Your search keywords did not match any jobs"
                  : props.displayText === "bookmark"
                  ? "You haven't bookmarked any jobs yet"
                  : ""
                : `Showing (${
                    displayTweets.slice(0, tweetsPerPage).length
                  }) of (${
                    displayTweets.length
                  }) remote jobs ${displayMessage}`}
            </Typography>
          </div>

          <div style={{paddingBottom: 8}}>
            <FormControl>
              <Select
                disableUnderline
                classes={{
                  root: clsx(outlineSelectClasses.select, classes.sortMenuWidth)
                }}
                MenuProps={menuProps}
                IconComponent={iconComponent}
                value={displayOrderIndex}
                onChange={handleSortMenuItemClick}
              >
                {sortMenuOptions.map((option, index) => (
                  <MenuItem value={index}>
                    <ListItemIcon
                      classes={{root: outlineSelectClasses.listIcon}}
                    >
                      <SortIcon />
                    </ListItemIcon>
                    <span style={{marginTop: 3}}>{option}</span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>

        <Grid container spacing={4}>
          {allTweets.length === 0
            ? [1, 2, 3, 4, 5, 6].map(card => (
                <Grid item key={card} xs={12} sm={6} md={4}>
                  <Card className={classes.card}>
                    <CardHeader
                      avatar={
                        <Skeleton
                          animation="wave"
                          variant="circle"
                          width={40}
                          height={40}
                        />
                      }
                      title={
                        <Skeleton
                          animation="wave"
                          height={10}
                          width="80%"
                          style={{marginBottom: 6}}
                        />
                      }
                      subheader={
                        <Skeleton animation="wave" height={10} width="40%" />
                      }
                    />

                    <CardContent>
                      <Skeleton animation="wave" variant="rect" height={100} />
                    </CardContent>
                  </Card>
                </Grid>
              ))
            : displayTweets.slice(0, tweetsPerPage).map(tweet => (
                <Grid item key={tweet.id} xs={12} sm={6} md={4}>
                  <Card className={classes.card}>
                    <CardHeader
                      style={{padding: "16px 16px 0px"}}
                      avatar={
                        <Link
                          href={`https://twitter.com/${tweet.user_screen_name}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          color={"textPrimary"}
                          underline="hover"
                        >
                          <Avatar
                            src={tweet.user_profile_image_url}
                            aria-label="user profile image"
                            alt={"user profile image"}
                            onClick={() =>
                              ReactGA.event({
                                category: "Card Header",
                                action:
                                  "Link to user twitter profile clicked via avatar"
                              })
                            }
                          />
                        </Link>
                      }
                      action={
                        <a
                          href={`https://twitter.com/${tweet.user_screen_name}/status/${tweet.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <IconButton
                            aria-label="twitter"
                            onClick={() =>
                              ReactGA.event({
                                category: "Card Header",
                                action:
                                  "Link to actual tweet clicked via twitter icon"
                              })
                            }
                          >
                            <TwitterIcon style={{color: "#1DA1F2"}} />
                          </IconButton>
                        </a>
                      }
                      title={
                        <Link
                          href={`https://twitter.com/${tweet.user_screen_name}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          color={"textPrimary"}
                          underline="hover"
                        >
                          <Typography
                            variant="body1"
                            className={clsx(
                              !expandCards[tweet.id] && classes.cardContent,
                              classes.cardContentCursor
                            )}
                            onClick={() =>
                              ReactGA.event({
                                category: "Card Header",
                                action:
                                  "Link to user twitter profile clicked via user_name"
                              })
                            }
                          >
                            <b>{tweet.job_title}</b>
                          </Typography>
                        </Link>
                      }
                    />
                    <CardContent style={{padding: "8px 16px 8px"}}>
                      <Typography
                        variant="body1"
                        color="textPrimary"
                        component="p"
                        gutterBottom
                        className={clsx(
                          !expandCards[tweet.id] && classes.cardContent,
                          classes.cardContentCursor
                        )}
                        onClick={() => handleCardExpansion(tweet)}
                      >
                        {tweet.full_text}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="textSecondary"
                        component="p"
                      >
                        {new Date(tweet.created_at)
                          .toString()
                          .substring(
                            0,
                            new Date(tweet.created_at)
                              .toString()
                              .lastIndexOf("GMT")
                          )}
                      </Typography>
                    </CardContent>
                    <Divider />
                    <CardActions
                      style={{
                        justifyContent: "space-between",
                        padding: "4px 8px"
                      }}
                    >
                      <div style={{display: "flex", alignItems: "center"}}>
                        <a
                          href={tweet.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            textDecoration: "none",
                            paddingLeft: 8,
                            marginRight: 8
                          }}
                        >
                          <Button
                            size="small"
                            color="primary"
                            style={{
                              textTransform: "none",
                              display: "flex"
                            }}
                            variant="contained"
                            onClick={() =>
                              ReactGA.event({
                                category: "Card Actions",
                                action: "Apply Link clicked"
                              })
                            }
                            endIcon={<OpenInNewIcon />}
                          >
                            Apply
                          </Button>
                        </a>
                        <Button
                          size="small"
                          color="primary"
                          style={{textTransform: "none"}}
                          variant="outlined"
                          endIcon={<ShareIcon />}
                          onClick={event =>
                            handleShareMenuOpen(event, tweet.full_text)
                          }
                        >
                          Share
                        </Button>
                      </div>
                      <div>
                        <IconButton
                          onClick={() => handleBookmark(tweet)}
                          variant=""
                          color={
                            isTweetBookmarked(tweet) ? "secondary" : "default"
                          }
                        >
                          {isTweetBookmarked(tweet) ? (
                            <BookmarkIcon />
                          ) : (
                            <BookmarkBorderIcon />
                          )}
                        </IconButton>
                      </div>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
        </Grid>

        <Menu
          id="social-menu"
          anchorEl={anchorElShare}
          keepMounted
          open={Boolean(anchorElShare)}
          onClose={handleShareMenuClose}
        >
          <Social full_text={shareText} />
        </Menu>

        <div className="jobsfeed-container-footer">
          <Fab
            style={{textTransform: "none"}}
            color="primary"
            variant="extended"
            size="medium"
            aria-label="show more jobs"
            disabled={tweetsPerPage >= displayTweets.length}
            onClick={() => {
              dispatch({
                type: ActionTypes.SET_TWEETS_PER_PAGE,
                tweetsPerPage: tweetsPerPage + DEFAULT_TWEETS_PER_PAGE
              });
            }}
          >
            Show more
            <ExpandMoreIcon />
          </Fab>
        </div>
      </Container>
    </div>
  );
}

export default Jobsfeed;
