import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionTypes from ".././data/ActionTypes.js";
import {useLocation} from "react-router-dom";
import Jobsfeed from ".././Jobsfeed.js";
import Jobstags from ".././Jobstags.js";
import Alert from "@material-ui/lab/Alert";

function Search() {
  const dispatch = useDispatch();
  const queryString = require("query-string");
  const parsed = queryString.parse(useLocation().search);
  // Global state
  const allTweets = useSelector(state => state.allTweets);
  const serverError = useSelector(state => state.serverError);
  const clientError = useSelector(state => state.clientError);

  const errorMessage = serverError || clientError;

  useEffect(() => {
    if (parsed.q !== undefined) {
      dispatch({
        type: ActionTypes.SET_DISPLAY_TWEETS_BY_SEARCH,
        searchText: parsed.q
      });
    }
  }, [dispatch, allTweets, parsed]);

  return (
    <div>
      <Jobstags />
      {errorMessage != null ? (
        <Alert className="error" severity="error">
          {errorMessage}
        </Alert>
      ) : (
        <Jobsfeed displayText="search" searchText={parsed.q} />
      )}
    </div>
  );
}

export default Search;
