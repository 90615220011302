import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionTypes from ".././data/ActionTypes.js";
import {useParams} from "react-router-dom";
import Jobsfeed from ".././Jobsfeed.js";
import Jobstags from ".././Jobstags.js";
import Alert from "@material-ui/lab/Alert";

function Job() {
  const dispatch = useDispatch();
  const urlParams = useParams();
  // Global state
  const allJobs = useSelector(state => state.allJobs);
  const allTweets = useSelector(state => state.allTweets);
  const serverError = useSelector(state => state.serverError);
  const clientError = useSelector(state => state.clientError);

  const errorMessage = serverError || clientError;

  useEffect(() => {
    if (allJobs.includes(urlParams.job) || urlParams.job === undefined) {
      dispatch({
        type: ActionTypes.SET_DISPLAY_TWEETS_BY_JOB,
        displayJob: urlParams.job || ""
      });
    } else if (urlParams.job !== undefined) {
      dispatch({
        type: ActionTypes.SET_ERROR_MESSAGE,
        errorMessage:
          "The url you followed is likely broken or the job category removed.",
        errorType: "clientError"
      });
    }
  }, [dispatch, allTweets, allJobs, urlParams]);

  return (
    <div>
      <Jobstags />
      {errorMessage !== null ? (
        <Alert className="error" severity="error">
          {errorMessage}
        </Alert>
      ) : (
        <Jobsfeed displayText="job" />
      )}
    </div>
  );
}

export default Job;
