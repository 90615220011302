import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionTypes from ".././data/ActionTypes.js";
import {useParams} from "react-router-dom";
import Jobsfeed from ".././Jobsfeed.js";
import Jobstags from ".././Jobstags.js";
import Alert from "@material-ui/lab/Alert";

function Job() {
  const dispatch = useDispatch();
  const urlParams = useParams();
  // Global state
  const allSkills = useSelector(state => state.allSkills);
  const allTweets = useSelector(state => state.allTweets);
  const serverError = useSelector(state => state.serverError);
  const clientError = useSelector(state => state.clientError);

  const errorMessage = serverError || clientError;
  
  useEffect(() => {
    if (allSkills.includes(urlParams.skill) || urlParams.skill === undefined) {
      dispatch({
        type: ActionTypes.SET_DISPLAY_TWEETS_BY_SKILL,
        displaySkill: urlParams.skill || ""
      });
    } else if (urlParams.skill !== undefined) {
      dispatch({
        type: ActionTypes.SET_ERROR_MESSAGE,
        errorMessage:
          "The url you followed is likely broken or the job category removed.",
        errorType: "clientError"
      });
    }
  }, [dispatch, allTweets, allSkills, urlParams]);

  return (
    <div>
      <Jobstags />
      {errorMessage !== null ? (
        <Alert className="error" severity="error">
          {errorMessage}
        </Alert>
      ) : (
        <Jobsfeed displayText="skill" />
      )}
    </div>
  );
}

export default Job;
