import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionTypes from ".././data/ActionTypes.js";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import {fade, makeStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import {useHistory} from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Fab from "@material-ui/core/Fab";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Zoom from "@material-ui/core/Zoom";
import Newsletter from "./Newsletter.js";
import {isMobileOnly} from "react-device-detect";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Badge from "@material-ui/core/Badge";
import ReactGA from "react-ga";
import ScrollIntoView from "react-scroll-into-view";

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    bottom: theme.spacing(2),
    right: theme.spacing(2)
  },
  search: {
    display: "flex",
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25)
    },
    marginLeft: 0,
    flex: 1,
    maxWidth: 1000,
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
      flex: 1
    }
  }
}));

function ScrollTop(props) {
  const {children} = props;
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100
  });

  const handleScroll = event => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({behavior: "smooth", block: "center"});
    }
  };

  return (
    <Zoom in={trigger}>
      <div onClick={handleScroll} role="presentation" className={classes.root}>
        {children}
      </div>
    </Zoom>
  );
}

export default function Home(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const allTweets = useSelector(state => state.allTweets);
  const bookmarkedTweets = useSelector(state => state.bookmarkedTweets);

  useEffect(() => {
    if (allTweets.length === 0) {
      // history.listen does not account for initial page load. So, we need to explicitly
      // log that here.
      ReactGA.pageview("/");
      fetch("/tweets")
        .then(function(data) {
          return data.json();
        })
        .then(data => {
          dispatch({
            type: ActionTypes.SET_ALL_TWEETS,
            allTweets: data
          });
        })
        .catch(function(error) {
          dispatch({
            type: ActionTypes.SET_ERROR_MESSAGE,
            errorMessage: "There was an issue fetching jobs.",
            errorType: "serverError"
          });
        });
    }
  }, [dispatch, allTweets]);

  const [searchText, setSearchText] = useState("");

  const handleChange = event => {
    setSearchText(event.target.value.trim());
  };

  const handleSearchClick = () => {
    if (searchText !== "") {
      history.push({
        pathname: "/search",
        search: "?q=" + encodeURIComponent(searchText)
      });
    }
  };

  const handleBookmarkClick = () => {
    history.push({pathname: "/bookmark"});
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="default"
        style={{backgroundColor: "white"}}
      >
        <Container>
          <Toolbar
            style={{display: "flex", justifyContent: "center", padding: 0}}
          >
            <Link
              href="/"
              color="textPrimary"
              align="left"
              style={{padding: 8}}
            >
              {isMobileOnly ? (
                <img
                  alt="main icon"
                  width="40px"
                  src={require(".././icons/main-icon.png")}
                  style={{display: "inline-flex", verticalAlign: "middle"}}
                />
              ) : (
                <img
                  alt="appbar icon"
                  width="40px"
                  src={require(".././icons/appbar-icon.png")}
                  style={{display: "inline-flex", verticalAlign: "middle"}}
                />
              )}
            </Link>
            <div className={classes.search}>
              <TextField
                style={{flex: 1}}
                variant="outlined"
                margin="dense"
                placeholder={"Search..."}
                onChange={handleChange}
                onKeyPress={event => {
                  if (event.key === "Enter") {
                    handleSearchClick();
                    event.preventDefault();
                  }
                }}
              />
            </div>
            <IconButton onClick={handleSearchClick}>
              <SearchIcon />
            </IconButton>
            {isMobileOnly ? (
              <ScrollIntoView selector="#jobstags-feed-separator">
                <IconButton onClick={handleBookmarkClick}>
                  <Badge
                    badgeContent={bookmarkedTweets.length}
                    color="secondary"
                  >
                    <BookmarkIcon
                      color={
                        bookmarkedTweets.length === 0 ? "default" : "primary"
                      }
                    />
                  </Badge>
                </IconButton>
              </ScrollIntoView>
            ) : (
              <Badge
                style={{marginRight: 12}}
                badgeContent={bookmarkedTweets.length}
                color="secondary"
              >
                <Button
                  style={{textTransform: "none"}}
                  variant={
                    bookmarkedTweets.length === 0 ? "filled" : "contained"
                  }
                  color="primary"
                  onClick={handleBookmarkClick}
                >
                  Bookmarks
                </Button>
              </Badge>
            )}
            <Newsletter />
          </Toolbar>
        </Container>
      </AppBar>
      <ScrollTop {...props}>
        <Fab
          color="secondary"
          size="small"
          aria-label="scroll back to top"
          onClick={() =>
            ReactGA.event({
              category: "scroll",
              action: "ScrollTop Button clicked"
            })
          }
        >
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
