import React from "react";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  LinkedinShareButton,
  LinkedinIcon
} from "react-share";
import MenuItem from "@material-ui/core/MenuItem";
import ReactGA from "react-ga";

function Social(props) {
  return (
    <div>
      <MenuItem>
        <FacebookShareButton
          url="www.findremotework.co"
          onClick={() =>
            ReactGA.event({
              category: "Card Social Media",
              action: "Facebook Share Button clicked"
            })
          }
        >
          <FacebookIcon size={40} round="true"></FacebookIcon>
        </FacebookShareButton>
      </MenuItem>
      <MenuItem>
        <TwitterShareButton
          url="www.findremotework.co"
          title={`${props.full_text}. \n\n Find more remote job listings at `}
          onClick={() =>
            ReactGA.event({
              category: "Card Social Media",
              action: "Twitter Share Button clicked"
            })
          }
        >
          <TwitterIcon size={40} round="true"></TwitterIcon>
        </TwitterShareButton>
      </MenuItem>
      <MenuItem>
        <WhatsappShareButton
          url="www.findremotework.co"
          title={`${props.full_text}. \n\n Find more remote job listings at`}
          onClick={() =>
            ReactGA.event({
              category: "Card Social Media",
              action: "Whatsapp Share Button clicked"
            })
          }
        >
          <WhatsappIcon size={40} round="true"></WhatsappIcon>
        </WhatsappShareButton>
      </MenuItem>
      <MenuItem>
        <LinkedinShareButton
          url="www.findremotework.co"
          title={`${props.full_text}. \n\n Find more remote job listings at`}
          onClick={() =>
            ReactGA.event({
              category: "Social Media Share",
              action: "Linkedin Share Button clicked"
            })
          }
        >
          <LinkedinIcon size={40} round="true"></LinkedinIcon>
        </LinkedinShareButton>
      </MenuItem>
    </div>
  );
}

export default Social;
