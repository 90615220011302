import React, {useState} from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import MailIcon from "@material-ui/icons/Mail";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import {isMobileOnly} from "react-device-detect";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function isEmailValid(email) {
  var validEmailRegEx = /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
  return validEmailRegEx.test(email);
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function Newsletter() {
  // Global state
  const allTags = ["engineering", "design", "business", "others"];

  // Local state
  const [dialogOpen, setDialogOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const emailValidError = !isEmailValid(email);
  const [signUpError, setSignUpError] = useState(false);

  const handleSignUp = () => {
    setDialogOpen(false);
    fetch("/newsletter", {
      method: "POST",
      body: JSON.stringify({email: email, tags: tags.toString()})
    })
      .then(data => {
        setSnackbarOpen(true);
        setEmail("");
        setTags([]);
      })
      .catch(function(error) {
        setSignUpError(true);
        setSnackbarOpen(true);
      });
  };

  const handleEmailChange = event => {
    setEmail(event.target.value);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setTags([]);
    setDialogOpen(false);
  };

  return (
    <div>
      {isMobileOnly ? (
        <IconButton onClick={handleDialogOpen}>
          <MailIcon color="primary" />
        </IconButton>
      ) : (
        <Button
          style={{textTransform: "none"}}
          variant="contained"
          color="primary"
          onClick={handleDialogOpen}
        >
          Newsletter
        </Button>
      )}

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Newsletter</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To receive a customized weekly newsletter with the latest remote job
            postings, please enter your email address and optionally select a
            job category.
          </DialogContentText>
          <Autocomplete
            id="tag-select"
            options={allTags}
            onChange={(event, value) => setTags(value)}
            getOptionLabel={option => option}
            renderOption={(option, {selected}) => (
              <React.Fragment>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{marginRight: 8}}
                  checked={selected}
                />
                {option}
              </React.Fragment>
            )}
            renderInput={params => (
              <TextField {...params} label="Job Category" />
            )}
          />
          <br />
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            value={email}
            required
            onChange={handleEmailChange}
            helperText={emailValidError ? "Please enter a valid email" : ""}
          />
        </DialogContent>
        <DialogActions style={{padding: "8px 16px"}}>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleDialogClose}
          >
            Cancel
          </Button>
          <Button
            disabled={emailValidError}
            onClick={handleSignUp}
            color="primary"
            variant="contained"
          >
            Sign Up
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={signUpError ? "error" : "success"}
        >
          {signUpError ? "Sorry, an error occurred!" : "Thanks for signing up!"}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default Newsletter;
