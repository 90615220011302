import React from "react";
import Chip from "@material-ui/core/Chip";
import ActionTypes from "./data/ActionTypes.js";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import IconButton from "@material-ui/core/IconButton";
import {isMobileOnly, isMobile} from "react-device-detect";
import ReactGA from "react-ga";
import {
  IoLogoJavascript,
  IoMdAnalytics,
  IoLogoNodejs,
  IoLogoPython,
  IoIosCloud
} from "react-icons/io";
import {FaSwift, FaPhp, FaJava, FaWordpress, FaApple} from "react-icons/fa";
import {DiRuby} from "react-icons/di";
import {AiOutlineConsoleSql, AiFillHtml5} from "react-icons/ai";
import {GrUserManager, GrTest} from "react-icons/gr";
import {GiChemicalDrop, GiTeacher} from "react-icons/gi";
import {TiCss3} from "react-icons/ti";
import {RiFileExcel2Line} from "react-icons/ri";
import {
  FcSalesPerformance,
  FcEngineering,
  FcAndroidOs,
  FcDataSheet,
  FcCustomerSupport,
  FcWebcam,
  FcSettings,
  FcIdea
} from "react-icons/fc";
import {
  MdSecurity,
  MdSystemUpdate,
  MdAttachMoney,
  MdTranslate,
  MdCreate
} from "react-icons/md";
import {FiCode, FiTrendingUp} from "react-icons/fi";

const jobIcons = {
  engineer: <FcEngineering size={24} />,
  android: <FcAndroidOs size={24} />,
  backend: <FcSettings size={24} />,
  frontend: <FcWebcam size={24} />,
  fullstack: <FiCode size={24} color="green" />,
  ios: <FaApple size={24} />,
  security: <MdSecurity size={24} color="red" />,
  systems: <MdSystemUpdate size={24} color="green" />,
  "product design": <MdCreate size={24} color="navy" />,
  marketing: <FiTrendingUp size={24} color="black" />,
  "content strategy": <FcIdea size={24} />,
  teaching: <GiTeacher size={24} color="orange" />,
  translator: <MdTranslate size={24} color="black" />,
  "data analyst": <FcDataSheet size={24} />,
  "customer service": <FcCustomerSupport size={24} />,
  accounting: <MdAttachMoney size={24} color="orange" />
};

const skillIcons = {
  python: <IoLogoPython size={24} />,
  javascript: <IoLogoJavascript size={24} color="gold" />,
  nodejs: <IoLogoNodejs size={24} color="green" />,
  swift: <FaSwift size={24} color="orange" />,
  php: <FaPhp size={24} color="blue" />,
  ruby: <DiRuby size={24} color="red" />,
  java: <FaJava size={24} color="navy" />,
  sql: <AiOutlineConsoleSql size={24} color="navy" />,
  excel: <RiFileExcel2Line size={24} color="green" />,
  wordpress: <FaWordpress size={24} />,
  html: <AiFillHtml5 size={24} color="red" />,
  css: <TiCss3 size={24} color="blue" />,
  saas: <IoIosCloud size={24} color="silver" />,
  research: <GiChemicalDrop size={24} color="brown" />,
  analytics: <IoMdAnalytics size={24} />,
  management: <GrUserManager size={24} />,
  qa: <GrTest size={24} />,
  sales: <FcSalesPerformance size={24} />
};

const colors = {
  "sky blue": "#68C4E3",
  chocolate: "#D86B31",
  "light steel blue": "#D3E5DD",
  "dark olive green": "#33743A",
  "steel blue": "#496BA4"
};

function Jobstags() {
  const history = useHistory();
  // Global state
  const allJobs = useSelector(state => state.allJobs);
  const displayJob = useSelector(state => state.displayJob);
  const allSkills = useSelector(state => state.allSkills);
  const displaySkill = useSelector(state => state.displaySkill);
  const chipType = useSelector(state => state.chipType);

  const handleChipClickJob = job => {
    if (displayJob !== job) {
      history.push({pathname: "/" + job});
    }
  };

  const handleChipClickSkill = skill => {
    if (displaySkill !== skill) {
      history.push({pathname: "/skill/" + skill});
    }
  };

  return (
    <div>
      <Container className="description-container">
        <Description />
        <div className="jobstags">
          {chipType === "job"
            ? allJobs.map(job => (
                <Chip
                  style={{margin: "0px 4px 8px"}}
                  color="primary"
                  size="medium"
                  label={job}
                  icon={jobIcons[job]}
                  variant={job === displayJob ? "default" : "outlined"}
                  onClick={() => handleChipClickJob(job)}
                />
              ))
            : allSkills.map(skill => (
                <Chip
                  style={{margin: "0px 4px 8px"}}
                  color="primary"
                  size="medium"
                  label={skill}
                  icon={skillIcons[skill]}
                  variant={skill === displaySkill ? "default" : "outlined"}
                  onClick={() => handleChipClickSkill(skill)}
                />
              ))}
        </div>
        {/* This adds some padding which is required for scroll to work on mobile */}
        {isMobileOnly && (
          <div
            id="jobstags-feed-separator"
            className="jobstags-feed-separator"
          />
        )}
      </Container>
    </div>
  );
}

function Description() {
  const dispatch = useDispatch();
  const chipType = useSelector(state => state.chipType);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-between"
      }}
    >
      <div
        id="back-to-top-anchor"
        style={{
          flex: "0 1 auto",
          maxWidth: "800px",
          alignSelf: "flex-start",
          padding: 20
        }}
      >
        <Typography variant="h3" paragraph>
          Find <u style={{color: colors["steel blue"]}}>remote work</u> around
          the world
        </Typography>

        <Typography variant="h6" color="textPrimary" paragraph>
          Explore our constantly evolving collection of 30 day fresh remote job
          offerings sourced via Twitter. Find your dream role and your dream job
          across continents and work from the comforts of your home. Browse by{" "}
          <u
            onClick={() =>
              chipType !== "job" &&
              dispatch({
                type: ActionTypes.SET_CHIP_TYPE,
                chipType: "job"
              })
            }
            style={{
              color: "rgb(56, 88, 152)",
              backgroundColor: chipType === "job" && "lightblue",
              textDecoration: "none",
              borderBottomStyle: "solid",
              cursor: "pointer"
            }}
          >
            role
          </u>{" "}
          or{" "}
          <u
            onClick={() =>
              chipType !== "skill" &&
              dispatch({
                type: ActionTypes.SET_CHIP_TYPE,
                chipType: "skill"
              })
            }
            style={{
              color: "rgb(56, 88, 152)",
              backgroundColor: chipType === "skill" && "lightblue",
              textDecoration: "none",
              borderBottomStyle: "solid",
              cursor: "pointer"
            }}
          >
            skill
          </u>
          {""}.
        </Typography>
      </div>

      {!isMobile && (
        <div
          style={{
            marginTop: 8
          }}
        >
          <img src={require("./icons/main-icon.png")} alt="Logo" height="192" />
        </div>
      )}

      {!isMobile && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column"
          }}
        >
          <a
            href="https://www.facebook.com/findremotework"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              onClick={() =>
                ReactGA.event({
                  category: "Header Social Media",
                  action: "Link to Facebook profile clicked"
                })
              }
            >
              <FacebookIcon style={{color: "#3b5998"}} />
            </IconButton>
          </a>
          <a
            href="https://www.instagram.com/findremotework/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              onClick={() =>
                ReactGA.event({
                  category: "Header Social Media",
                  action: "Link to Instagram profile clicked"
                })
              }
            >
              <InstagramIcon style={{color: "salmon"}} />
            </IconButton>
          </a>
          <a
            href="https://twitter.com/findremotework_"
            target="_blank"
            rel="noopener noreferrer"
          >
            <IconButton
              onClick={() =>
                ReactGA.event({
                  category: "Header Social Media",
                  action: "Link to Twitter profile clicked"
                })
              }
            >
              <TwitterIcon style={{color: "#1DA1F2"}} />
            </IconButton>
          </a>
        </div>
      )}
    </div>
  );
}

export default Jobstags;
