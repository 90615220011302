import React from "react";
import Typography from "@material-ui/core/Typography";
import {makeStyles} from "@material-ui/core/styles";
import FavoriteIcon from "@material-ui/icons/Favorite";
import {red, blue} from "@material-ui/core/colors";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import IconButton from "@material-ui/core/IconButton";
import ReactGA from "react-ga";

const useStyles = makeStyles(theme => ({
  footer: {
    backgroundColor: "rgba(0,0,0,0.05)",
    padding: theme.spacing(1)
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex"
  },
  link: {
    textDecoration: "none"
  }
}));

function Copyright() {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="body2" color="textPrimary" align="center">
        Find Remote Work {new Date().getFullYear()}
        <br />
        <Typography variant="body2" align="center">
          Made with{" "}
          <FavoriteIcon
            className={classes.wrapIcon}
            style={{color: red[500]}}
          />{" "}
          by{" "}
          <a
            className={classes.link}
            href="https://twitter.com/ab7_ab7"
            target="_blank"
            rel="noopener noreferrer"
            style={{color: blue[700]}}
          >
            {"@ab7_ab7"}
          </a>{" "}
          and{" "}
          <a
            className={classes.link}
            href="https://aayushbhutani.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{color: blue[700]}}
          >
            {"@aayushbhutani26"}
          </a>
        </Typography>
        <Typography variant="caption" color="textSecondary" align="center">
          The job listings shown here are not affiliated with findremotework.co
          in any way and therefore do not imply endorsement or sponsorship.
          Illustrations by Aashima Khanna.
        </Typography>
        <br />
        <a
          href="https://www.facebook.com/findremotework"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton
            onClick={() =>
              ReactGA.event({
                category: "Footer Social Media",
                action: "Link to Facebook profile clicked"
              })
            }
          >
            <FacebookIcon />
          </IconButton>
        </a>
        <a
          href="https://www.instagram.com/findremotework/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton
            onClick={() =>
              ReactGA.event({
                category: "Footer Social Media",
                action: "Link to Instagram profile clicked"
              })
            }
          >
            <InstagramIcon />
          </IconButton>
        </a>
        <a
          href="https://twitter.com/findremotework_"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconButton
            onClick={() =>
              ReactGA.event({
                category: "Footer Social Media",
                action: "Link to Twitter profile clicked"
              })
            }
          >
            <TwitterIcon />
          </IconButton>
        </a>{" "}
        <br/>
        <a
          href="https://peachplugin.com/?ref=findremotework"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src={require(".././icons/peachBadgeRecWhite.png")}
            alt="peachBadge"
            height="60"
            width="200"
          />
        </a>{" "}
      </Typography>
    </div>
  );
}

function Footer() {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Copyright />
    </footer>
  );
}

export default Footer;
