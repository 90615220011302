import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import ActionTypes from ".././data/ActionTypes.js";
import Jobsfeed from ".././Jobsfeed.js";
import Jobstags from ".././Jobstags.js";
import Alert from "@material-ui/lab/Alert";

function Bookmark() {
  const dispatch = useDispatch();
  // Global state
  const allTweets = useSelector(state => state.allTweets);
  const serverError = useSelector(state => state.serverError);
  const clientError = useSelector(state => state.clientError);

  const errorMessage = serverError || clientError;

  useEffect(() => {
    dispatch({
      type: ActionTypes.SET_DISPLAY_TWEETS_BY_BOOKMARK
    })
  }, [dispatch, allTweets]);

  return (
    <div>
      <Jobstags />
      {errorMessage != null ? (
        <Alert className="error" severity="error">
          {errorMessage}
        </Alert>
      ) : (
        <Jobsfeed displayText="bookmark" />
      )}
    </div>
  );
}

export default Bookmark;
