const ActionTypes = {
  SET_ALL_TWEETS: "SET_ALL_TWEETS",
  SET_DISPLAY_TWEETS_BY_JOB: "SET_DISPLAY_TWEETS_BY_JOB",
  SET_DISPLAY_TWEETS_BY_SEARCH: "SET_DISPLAY_TWEETS_BY_SEARCH",
  SET_DISPLAY_TWEETS_BY_BOOKMARK: "SET_DISPLAY_TWEETS_BY_BOOKMARK",
  SET_DISPLAY_TWEETS_BY_SKILL: "SET_DISPLAY_TWEETS_BY_SKILL",
  SET_TWEETS_PER_PAGE: "SET_TWEETS_PER_PAGE",
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGE",
  SET_DISPLAY_ORDER_INDEX: "SET_DISPLAY_ORDER_INDEX",
  SET_BOOKMARKED_TWEETS: "SET_BOOKMARKED_TWEETS",
  SET_CHIP_TYPE: "SET_CHIP_TYPE"
};

export default ActionTypes;
